.main-div{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px 28px;
    box-sizing: border-box;
    overflow: auto;
    background-color: var(--color-white);
}

@media only screen and (min-width: 768px){
    .main-div{
        padding: 40px 56px;
    }
}

@media only screen and (min-width: 1200px) {
    *{
        font-size: 18px;
    }
}