.parent-div {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

form {
    width: 90%;
    display: flex;
    flex-direction: column;
}

.input-field {
    width: 100%;
    display: block;
    outline: none;
    border: none;
    background-color: var(--color-secondary);
    padding: 12px;
    border-radius: 4px;
    margin-top: 16px;
    box-sizing: border-box;
    color: var(--color-black);
    font-size: 0.8rem;
    font-weight: 500;
}

.send-email-button {
    padding: 8px 40px;
    align-self: center;
}

::placeholder {
    color: var(--color-grey);
}

:-ms-input-placeholder {
    color: var(--color-grey);
}

::-ms-input-placeholder {
    color: var(--color-grey);
}

@media only screen and (min-width: 768px) {

    form {
        width: 60%;
        display: flex;
        flex-direction: column;
    }

    .name-email-div {
        display: flex;
        gap: 16px;
    }
}