@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

:root{
  --color-primary: #557370;
  --color-secondary: #A3BCBF;
  --color-black: #262626;
  --color-white: #FDFCF8;
  --color-verylight-green: #E8EDED;
  --color-grey: #595959;
  --filter-green: invert(80%) sepia(14%) saturate(311%) hue-rotate(138deg) brightness(92%) contrast(84%);
}

*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  color: var(--color-black);
}

html, body, #root{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

body {  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
