.parent-div {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
}

#name {
    letter-spacing: 6px;
    font-weight: 500;
    font-size: 1.8rem;
}

#role {
    color: var(--color-primary);
    font-size: 1.1rem;
}

#description {
    color: var(--color-grey);
    font-size: 0.9rem;
    margin-top: 16px;
}

.icons-div {
    margin-top: 40px;
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);
}

.icons-div div {
    text-align: center;
}

.skills-icons img {
    width: 2.5rem;
    height: 2.5rem;
}

.skills-icons p {
    font-size: 0.7rem;
    color: var(--color-black);
    margin-top: 4px;
    text-align: center;
    white-space: break-spaces;
}

@keyframes circleAnimation {

    0% {
        transform: translate(-50%, -50%) rotate(var(--i)) translate(0px, -125px) rotate(calc(-1 * var(--i)));
    }

    100% {
        transform: translate(-50%, -50%) rotate(calc(var(--i) + 360deg)) translate(0px, -125px) rotate(calc(-1 * (var(--i) + 360deg)));
    }
}

@media only screen and (min-width: 768px) {

    .parent-div {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    #description {
        width: 80%;
    }

    .intro-skills-div {
        display: grid;
        width: 50%;
        height: fit-content;
        place-items: center;
        --circleDiameter: 250px;
        --planets: calc(var(--circleDiameter) / 7)
    }

    .skills-inner-div {
        position: relative;
        width: var(--circleDiameter);
        height: var(--circleDiameter)
    }

    .icons-div {
        display: block;
        margin-top: 0px;
        position: relative;
        top: -7px;
        left: -7px;
        width: 100%;
        height: 100%;
        border-radius: 100%;
    }

    .skills-icons {
        position: absolute;
        top: 50%;
        left: 50%;
        width: var(--planets);
        height: var(--planets);
        transform: translate(-50%, -50%) rotate(var(--i)) translate(0px, -125px) rotate(calc(-1 * var(--i)));
        animation: circleAnimation 50s linear infinite;
    }

    .skills-icons>img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .skills-icons p {
        position: absolute;
        left: 50%;
        top: 90%;
        transform: translate(-50%, 0px);
        font-size: 0.6rem;
    }

    .skills-icons:nth-child(1) {
        --i: 0deg;
    }

    .skills-icons:nth-child(2) {
        --i: 45deg;
    }

    .skills-icons:nth-child(3) {
        --i: 90deg;
    }

    .skills-icons:nth-child(4) {
        --i: 135deg;
    }

    .skills-icons:nth-child(5) {
        --i: 180deg;
    }

    .skills-icons:nth-child(6) {
        --i: 225deg;
    }

    .skills-icons:nth-child(7) {
        --i: 270deg;
    }

    .skills-icons:nth-child(8) {
        --i: 315deg;
    }

}

@media only screen and (min-width: 1200px) {
    .intro-skills-div {
        --planets: calc(var(--circleDiameter) / 6.5)
    }
}