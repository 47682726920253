.parent-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#logo {
    width: 70px;
    height: 90px;
}

.header-icons-div {
    display: flex;
    justify-content: space-between;
}

.header-icons {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.header-icons:hover {
    filter: var(--filter-green);
}

.header-nav-div {
    gap: 40px;
    font-size: 1rem;
    display: flex;
    box-sizing: border-box;
    padding: 120px 50px;
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    right: 0;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    background-color: var(--color-white);
    box-shadow: 5px 5px 20px 10px var(--color-verylight-green);
}

.header-nav-div.hide {
    display: none;
}

.header-navlink{
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}

.header-navlink:hover {
    color: var(--color-secondary);
}

.navlink-active {
    color: var(--color-secondary);
}

#menuIcon {
    width: 32px;
    height: 32px;
    cursor: pointer;
}

#menuIcon:hover{
    filter: var(--filter-green);
}

#closeIcon {
    position: absolute;
    top: 62px;
    right: 40px;
    width: 28px;
    height: 28px;
    cursor: pointer;
}

#closeIcon:hover {
    filter: var(--filter-green);
}

@media only screen and (min-width: 768px) {

    #logo {
        width: 100px;
        height: 110px;
    }

    .header-icons {
        width: 24px;
        height: 24px;
    }

    #menuIcon,
    #closeIcon {
        display: none;
    }

    .header-nav-div,
    .header-nav-div.hide {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 1rem;
        padding: 0;
        position: relative;
        box-shadow: none;
    }

}