.swiper-slide {
    text-align: center;
    padding-bottom: 40px;
}

.swiper-slide img {
    width: 100%;
    height: auto;
}

.project-name {
    margin-top: 8px;
    font-size: 1rem;
    color: var(--color-black);
}

.project-desc {
    font-size: 0.8rem;
    color: var(--color-grey);
    text-align: center;
    padding: 0 10%;
}

.project-tools {
    font-size: 0.8rem;
    color: var(--color-primary);
    margin-top: 8px;
    font-weight: 500;
    letter-spacing: 1px;
}

.link-buttons-div {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 16px;
}

@media only screen and (min-width: 768px) {

    .swiper-slide img {
        width: 90%;
        height: auto;
    }
}