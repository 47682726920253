.footer-parent-div {
    margin-top: 60px;
    box-sizing: border-box;
}

.hr-div {
    width: 100%;
    height: 0.8px;
    background-color: var(--color-secondary);
}

.footer-main-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0px;
    box-sizing: border-box;
    position: relative;
}

#footerLogo {
    width: 60px;
    height: 80px;
}

.footer-info-div {
    display: flex;
    flex-direction: column;
}

.logo-div {
    align-self: center;
    margin-bottom: 16px;
    cursor: pointer;
}

.footer-icons-div {
    display: flex;
    justify-content: space-between;
}

.footer-icons-div img,
.email-div img {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.footer-icons-div img:hover,
.email-div img:hover {
    filter: var(--filter-green);
}

.email-div {
    display: flex;
    align-items: center;
    margin-top: 8px;
    cursor: pointer;
}

.email-div span {
    margin-left: 8px;
    font-size: 0.7rem;
}

.footer-nav-div {
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-size: 0.7rem;
    font-weight: 500;
}

.footer-navlink {
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}

.footer-navlink:hover {
    color: var(--color-secondary);
}

@media only screen and (min-width: 768px) {
    .logo-div {
        position: absolute;
        left: 50%;
        right: 50%;
        top: 20%;
        margin: auto;
    }

    #footerLogo {
        width: 100px;
        height: 120px;
    }

    .footer-icons-div img,
    .email-div img {
        width: 24px;
        height: 24px;
    }

    .email-div span {
        font-size: 1rem;
    }

    .footer-nav-div {
        font-size: 1rem;
        flex-direction: row;
    }
}