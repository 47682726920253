.parent-div {
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
}

.horizontal-line {
    width: 100%;
    height: 0.8px;
    opacity: 0.8;
    background-color: var(--color-secondary);
}

.heading {
    letter-spacing: 3px;
    font-size: 1rem;
    flex-shrink: 0;
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .parent-div {
        gap: 40px;
    }

    .heading {
        letter-spacing: 6px;
        font-size: 1.2rem;
    }

    .horizontal-line {
        height: 0.9px;
    }
}